<template>
  <div>
    <div class="columns pl-3">
      <div class="column is-4">
        <b-field label="Cage serial" label-position="inside">
          <b-input v-model="serial"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-button type="is-primary" size="is-medium" @click="create(serial)">Create</b-button>
      </div>
    </div>
    <b-table :data="items"
             :sort-icon="sortIcon"
             default-sort="createdAt"
             default-sort-direction="desc"
             paginated>
      <template v-slot="props">
        <b-table-column label="Serial Number" field="serial" searchable sortable>
          {{ props.row.serial }}
        </b-table-column>
        <b-table-column label="Created by" field="username" searchable sortable>
          {{ props.row.username }}
        </b-table-column>
        <b-table-column label="Created at" field="createdAt" searchable sortable>
          {{ props.row.createdAt | moment }}
        </b-table-column>
        <b-table-column field="Copy" centered>
          <b-button type="is-primary" @click="copy(props.row)">
            Copy
          </b-button>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import {API} from "aws-amplify";
import gql from 'graphql-tag'

const listQuery = gql`
  query list($nextToken: String) {
    listLicenses(limit: 1000, nextToken: $nextToken) {
      items {
        createdAt
        license
        serial
        username
      }
      nextToken
    }
  }
`

const createMutation = gql`
  mutation add($serial: String!) {
    addLicense(serial: $serial) {
      createdAt
      license
      serial
      username
    }
  }
`

export default {
  name: 'home',
  components: {},
  props: {},
  data() {
    return {
      items: [],
      sortIcon: 'arrow-up',
      serial: ""
    }
  },
  methods: {
    async getItems(nextToken = null) {
      return API.graphql({
        query: listQuery,
        variables: {
          nextToken: nextToken
        }
      })
    },
    async getAllItems(nextToken = null) {
      const results = await this.getItems(nextToken)
      if (results.data.listLicenses.nextToken != null)
        return results.data.listLicenses.items.concat(await this.getAllItems(results.data.listLicenses.nextToken))
      else
        return results.data.listLicenses.items
    },
    async create(serial) {
      await API.graphql({
        query: createMutation,
        variables: {
          serial: serial
        }
      })
      this.items = []
      this.items = await this.getAllItems()
    },
    async copy(row) {
      this.$buefy.toast.open({
        message: 'Copied ' + row.serial,
        type: 'is-primary',
        position: 'is-top-right'
      })
      await navigator.clipboard.writeText(row.license.match(/(.{1,60})/g).join('\n'))
    }
  },
  mounted: async function () {
    this.items = await this.getAllItems()
  }
}
</script>

<style scoped>
.noborder {
  border-style: none;
  background: transparent;
}
</style>