import Vue from 'vue'
import App from './App.vue'
import '@aws-amplify/ui-vue'
import '@mdi/font/css/materialdesignicons.css'
import Amplify from 'aws-amplify'
import axios from 'axios'
import Buefy from 'buefy'
import VueMoment from 'vue-moment'
import Clipboard from 'v-clipboard'

axios.get("config.json").then((response) => {
  Amplify.configure(response.data)
  Vue.config.productionTip = false
  Vue.config.devtools = true;
  Vue.use(Buefy)
  Vue.use(VueMoment)
  Vue.use(Clipboard)
  new Vue({
    render: h => h(App),
  }).$mount('#app')
})