<template>
  <div id="app">
    <div v-if="!signedIn">
    </div>
    <div v-if="signedIn">
      <div class="columns p-2">
        <div class="column is-2">
          <amplify-sign-out></amplify-sign-out>
        </div>
      </div>
      <licenses></licenses>
    </div>
  </div>
</template>

<script>
import {Hub} from '@aws-amplify/core';
import {Auth} from 'aws-amplify'
import Licenses from "./components/Licenses";

export default {
  name: 'App',
  components: {
    Licenses
  },
  created() {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          this.signedIn = true
          break;
        case 'signOut':
          this.signedIn = false
          break;
      }
    });
    if (window.location.search === "")
      this.checkLogin();
  },
  updated() {
    this.checkLogin();
  },
  methods: {
    async checkLogin() {
      try {
        const user = await Auth.currentAuthenticatedUser()
        console.log(user)
        this.signedIn = true
      } catch (err) {
        this.signedIn = false
        await Auth.federatedSignIn();
      }
    }
  },
  data() {
    return {
      signedIn: false
    }
  }
}
</script>

<style lang="scss">
@import "~bulma/sass/utilities/_all";
$primary: #ff0000;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
$toast-border-radius: $radius-large;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
:root {
  --amplify-primary-color: #ff0000;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}
</style>
